@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  fa-icon {
    display: flex;
    color: var(--loadingIndicatorColor);
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
}

.absoluteCenter {
  position: absolute;
  left: 50% !important;
  top: 50% !important;
}

.relativeCenter {
  position: relative;
  left: -50%;
  top: -50%;
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes spinner-color {
  100%,
  0% {
    stroke: var(--color-light-primary, var(--brandColor));
  }

  45% {
    stroke: var(--brandColor);
  }

  80%,
  90% {
    stroke: var(--color-dark-primary, var(--brandColor));
  }
}

.spinner-svg {
  animation-duration: 2s;
  animation: spinner-rotate 2s linear infinite;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  inset: 0;
  margin: auto;
}
.spinner-circle {
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke: #d62d20;
  animation:
    spinner-dash 1.5s ease-in-out infinite,
    spinner-color 6s ease-in-out infinite;
  stroke-linecap: round;
}
